var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('CustomerBySectorStackedChart',{attrs:{"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น',"customerCategory":1,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น',"customerCategory":1,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม สถานศึกษา',"customerCategory":2,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม สถานศึกษา',"customerCategory":2,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข',"customerCategory":9,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข',"customerCategory":9,"officeSectorID":[
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '58',
            '60',
            '61',
            '63',
            '66',
          ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }