<template>
    <v-container>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <ICTSolutionCustomerBySectorChart
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>

        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>



      </v-layout>
    </v-container>
  </template>
  
  <script>
  import ICTSolutionCustomerBySectorChart from "./ICTSolutionCustomerBySectorChart.vue";
  import ICTSolutionCustomerPerCustomerBySectorStackedChart from "./ICTSolutionCustomerPerCustomerBySectorStackedChart.vue";
  import ICTSolutionCustomerPerCustomerPercentBySectorStackedChart from "./ICTSolutionCustomerPerCustomerPercentBySectorStackedChart.vue";
  
  
  export default {
    name: "ICTSolutionCustomeDashboard",
    components: {
      ICTSolutionCustomerBySectorChart,
      ICTSolutionCustomerPerCustomerBySectorStackedChart,
      ICTSolutionCustomerPerCustomerPercentBySectorStackedChart
  
    },
    props: {

    },
  };
  </script>
  

  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>