<template>
  <div class="container">
    <v-card>
      <div v-if="hasSeries">
        <vue-apex-charts
          type="bar"
          :options="options"
          :series="series"
          height="350"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

export default {
  name: "CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart",
  props: {
    customerCategory: {
      type: Number,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    officeSectorID: {
      type: Array,
      required: true,
    },
  },
  components: { VueApexCharts },
  data: () => ({
    loaded: false,
    interval: null,
    hasSeries: false,
    options: {
      chart: {
        id: "CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart",
        stacked: true,
        animations: {
          enabled: true,
          easing: "linear",
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: ["#41B883", "#E46651"],
      xaxis: {
        categories: null,
        labels: {
          rotate: -45,
          rotateAlways: true,
          trim: false,
        },
      },
      title: {
        align: "center",
        text: "",
      },
    },

    series: [
      {
        name: "ลูกค้าที่ใช้บริการแล้ว",
        data: null,
      },
      {
        name: "ลูกค้าที่ยังไม่ได้ใช้บริการ",
        data: null,
      },
    ],
  }),
  mounted() {
    this.loaded = false;
    this.hasSeries = false;
    this.getDataFromAPI();
    // this.interval = setInterval(() => {
    //   this.getDataFromAPI();
    // }, 60000);
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  methods: {
    getDataFromAPI() {
      const queryParams = {
        params: {
          customerCategory: this.customerCategory,
          officeSectorID: this.officeSectorID,
        },
      };
      axios
        .get("/dashboard/customer-has-crm-data-or-special-bill-data-per-customer-by-sector-stacked-bar-chart", queryParams)
        .then((response) => {
          const result = response.data;
          this.options.xaxis.categories = result.series.map(
            (a) => a.officeSector.name
          );
          this.series[0].data = result.series.map((a) => a.customerHasCRMDataOrSpecialBillData);
          this.series[1].data = result.series.map((a) => a.customerNotHasCRMDataOrSpecialBillData);
          this.options.title.text = this.chartTitle;
          this.hasSeries = result.series.length > 0;
          this.loaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
};
</script>


