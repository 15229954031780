var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('ICTSolutionIncomeBySectorChart',{attrs:{"officeSectorID":[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('MonthlyICTSolutionIncomeBySectorLineChart',{attrs:{"officeSectorID":[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }