<template>
    <v-container>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <CustomerBySectorStackedChart
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <!-- <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :serviceCategory="[
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :serviceCategory="[
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :serviceCategory="[
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :serviceCategory="[
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex> -->
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น'"
            :customerCategory="1"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
      
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา'"
            :customerCategory="2"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข'"
            :customerCategory="9"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
  
  <!--
  
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ ICT Solution'"
            :customerCategory="1"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ ICT Solution'"
            :customerCategory="1"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ ICT Solution'"
            :customerCategory="2"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ ICT Solution'"
            :customerCategory="2"
            :serviceCategory="[
              '22',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Network'"
            :customerCategory="1"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Network'"
            :customerCategory="1"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Network'"
            :customerCategory="2"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Network'"
            :customerCategory="2"
            :serviceCategory="[
              '2',
              '3',
              '4',
              '5',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="1"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="1"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="2"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Smart Voice Solution ภก.1'"
            :customerCategory="2"
            :serviceCategory="[
              '20',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Complex Network'"
            :customerCategory="1"
            :serviceCategory="[
              '17',
              '18',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ Complex Network'"
            :customerCategory="1"
            :serviceCategory="[
              '17',
              '18'
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Complex Network'"
            :customerCategory="2"
            :serviceCategory="[
              '17',
              '18',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ Complex Network'"
            :customerCategory="2"
            :serviceCategory="[
              '17',
              '18'
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ MA'"
            :customerCategory="1"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น ที่ใช้บริการ MA'"
            :customerCategory="1"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerBySectorStackedChart
            :chartTitle="'จำนวนลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ MA'"
            :customerCategory="2"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6 grow pa-1>
          <CustomerUseServicePerCustomerPercentBySectorStackedChart
            :chartTitle="'เปอร์เซ็นต์ลูกค้ากลุ่ม สถานศึกษา ที่ใช้บริการ MA'"
            :customerCategory="2"
            :serviceCategory="[
              '21',
            ]"
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
      -->
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import CustomerBySectorStackedChart from "./CustomerBySectorStackedChart.vue";
  // import CustomerUseServicePerCustomerBySectorStackedChart from "./Dashboard/CustomerUseServicePerCustomerBySectorStackedChart.vue";
  // import CustomerUseServicePerCustomerPercentBySectorStackedChart from "./Dashboard/CustomerUseServicePerCustomerPercentBySectorStackedChart.vue";
  import CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart from "./CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart.vue";
  import CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart from "./CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart.vue";
  
  
  export default {
    name: "CustomerDashboard",
    components: {
      CustomerBySectorStackedChart,
      // CustomerUseServicePerCustomerBySectorStackedChart,
      // CustomerUseServicePerCustomerPercentBySectorStackedChart,
      CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart,
      CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart
  
    },
    props: {
      // msg: String,
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>