<template>
    <v-tabs color="black">
      <v-tab :to="'#customer'">ลูกค้า</v-tab>
      <v-tab :to="'#ict-solution-customer'">ลูกค้า ICT Solution</v-tab>
      <v-tab :to="'#ict-solution-income'">รายได้ ICT Solution</v-tab>
      <v-tab :to="'#customer-meeting'">การเข้าพบลูกค้า</v-tab>

      <v-tab-item value="customer">
        <Customer />
      </v-tab-item>
      <v-tab-item value="ict-solution-customer">
        <ICTSolutionCustomer />
      </v-tab-item>
      <v-tab-item value="ict-solution-income">
        <ICTSolutionIncome />
      </v-tab-item>
      <v-tab-item value="customer-meeting">
        <CustomerMeeting />
      </v-tab-item>
    </v-tabs>
  </template>
  
  <script>
  import Customer from "./Customer/index.vue";
  import ICTSolutionCustomer from "./ICTSolutionCustomer/index.vue";
  import ICTSolutionIncome from "./ICTSolutionIncome/index.vue";
  import CustomerMeeting from "./CustomerMeeting/index.vue";

  
  export default {
    name: "Dashboard",
    components: {
      Customer,
      ICTSolutionCustomer,
      ICTSolutionIncome,
      CustomerMeeting
    },

  };
  </script>

  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>